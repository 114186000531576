import * as React from 'react'
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputFieldProps,
  NumberInputProps,
  NumberInputStepper
} from '@packages/ui-components'
import { ErrorMessage, useField } from 'formik'
import { BaseProps } from './base-props'

export type NumberInputControlProps = BaseProps &
  NumberInputFieldProps & {
    numberInputProps?: NumberInputProps
    numberInputFieldProps?: NumberInputFieldProps
  }

const PercentInputControl = (props: NumberInputControlProps) => {
  const { name, numberInputProps, numberInputFieldProps, ...rest } = props

  const [field, { error, touched, value: fieldValue }, { setValue }] =
    useField(name)

  const $setFieldValue = (valueString: string) => {
    setValue(valueString)
  }

  return (
    <FormControl name={name} isInvalid={!!(error && touched)} {...rest}>
      <FormLabel htmlFor={props.name}>{props.label}</FormLabel>
      <Flex width="full">
        <InputGroup>
          <NumberInput
            {...field}
            isInvalid={!!error && touched}
            value={fieldValue}
            onChange={$setFieldValue}
            min={0}
            max={99.99}
            step={0.1}
            width="full"
            size="lg"
            pattern="^[1-9]{1}[0-9]?(?:\.\d{1,2})?$|^0\.\d{1,2}?$"
            borderRadius="4px"
            bgColor="white"
            {...numberInputProps}
          >
            <NumberInputField borderRadius="md" {...numberInputFieldProps} />
            <InputRightElement children="%" fontSize={18} marginRight={5} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </InputGroup>
      </Flex>

      <ErrorMessage component={FormErrorMessage} name={props.name} />
    </FormControl>
  )
}

export default PercentInputControl
