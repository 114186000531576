export default {
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  gray: {
    50: '#F7F8FA',
    60: '#F1F1F1',
    // 100: '#D7D7D7',
    250: '#C4C4C4',
    230: '#B7C0C5',
    300: '#E2E7EE',
    500: '#8C8C8C',
    //   300: '#757575',
    //   400: '#4d4d4d',
    //   900: '#212121',
  },
  red: {
    50: '#FFF4F6',
    400: '#DF3550',
  },
  blue: {
    100: '#EFF6FE',
    200: '#D9E3ED',
    400: '#C9E2FA',
    500: '#0B8CDB',
    800: '#005587',
    900: '#004b78',
  },
  orange: {
    900: '#DE9629',
  },
  yellow: {
    400: '#FFEA30',
  },
  green: {
    50: '#ECF4ED',
    400: '#48AD51',
  },
  teal: {
    400: '#00877F',
  },
}
