import React, { FC, ReactNode } from 'react'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Text
} from '@chakra-ui/react'
import { useField, useFormikContext } from 'formik'
import { BaseProps } from './base-props'
import Interpose from './Interpose'

export type SliderControlProps = BaseProps & {
  children?: ReactNode
  max?: number
  min?: number
  tabs?: boolean
  labels?: boolean
}

export const SliderControl: FC<SliderControlProps> = (
  props: SliderControlProps
) => {
  const { name, label, children, ...rest } = props
  const [field, meta] = useField(name)
  const formik = useFormikContext()
  const { onBlur, onChange, ...restField } = field

  // Does not behave like expected, so we manually handle it.
  const handleBlur = () => {
    formik.setFieldTouched(name, true)
  }
  const handleChange = (value: number) => {
    formik.setFieldValue(name, value)
  }

  const max = rest.max ?? 5
  const min = rest.min ?? 1
  const notches = [...new Array(max - min + 1)]
  const tabs = rest.tabs ?? true
  const labels = rest.labels ?? true

  const setDefaultValue = () => {
    if (field.value === undefined) {
      return formik.setFieldValue(name, 3)
    }
    return field.value
  }
  return (
    <FormControl
      id={name}
      name={name}
      isInvalid={!!(meta.error && meta.touched)}
      isRequired={props.isRequired}
      {...rest}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {props.description && (
        <Text fontFamily="thin" fontSize="sm" mb="4" color="gray.700">
          {props.description}
        </Text>
      )}
      <Slider
        onBlur={handleBlur}
        onChange={handleChange}
        {...restField}
        min={min}
        max={max}
        step={1}
        mb="7"
        value={setDefaultValue()}
      >
        <Box ml="5px" mr="5px" position="relative">
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
          <Flex mt={2} position="absolute" left={0} right={0}>
            <Interpose separator={<Spacer />}>
              {notches.map((_, x) => (
                <Box key={x} width={0}>
                  <Box
                    transform="translate(-50%, 0px)"
                    fontSize="sm"
                    width="min-content"
                  >
                    {!tabs ? (
                      <Text mt={1} color="blue.900" fontSize="8px">
                        |
                      </Text>
                    ) : (
                      x + min
                    )}
                  </Box>
                </Box>
              ))}
            </Interpose>
          </Flex>
        </Box>
        <Flex mt={10} position="absolute" left={0} right={0}>
          <Box fontSize="xs" fontFamily={'thin'}>
            {!labels ? 'Not Important' : 'Not Well'}
          </Box>
          <Spacer />
          <Box fontSize="xs" fontFamily={'thin'}>
            {!labels ? 'Very Important' : 'Very Well'}
          </Box>
        </Flex>
      </Slider>
    </FormControl>
  )
}
