import { extendTheme } from '@chakra-ui/react'
import { StepsStyleConfig as Steps } from 'chakra-ui-steps'
// Global style overrides
import styles from './styles'
import colors from './colors'
import fonts from './fonts'
// Foundational style overrides
// import borders from "./foundations/borders";
// Component style overrides

import Button from './components/button'
import Text from './components/text'
import { Dict } from '@chakra-ui/utils'

const CustomSteps = {
  ...Steps,
  baseStyle: (props: {
    [x: string]: unknown
    colorScheme: string
    colorMode: 'dark' | 'light'
    orientation: 'horizontal' | 'vertical' | undefined
    theme: Dict<unknown>
  }) => {
    return {
      ...Steps.baseStyle(props),
      stepContainer: {
        ...Steps.baseStyle(props).stepContainer,
        _hover: {
          bg: '#D6EBFF',
          color: 'gray.700',
          cursor: 'pointer',
        },
        '*[data-highlighted]': {
          borderColor: 'transparent',
          bg: 'transparent',
        },
        my: 2,
        borderRadius: 'md',
        w: '264px',
        textAlign: 'left',
        '*[aria-current=step]': {
          borderColor: 'transparent',
          bg: 'transparent',
        },
      },

      stepIconContainer: {
        ...Steps.baseStyle(props).stepIconContainer,
        bg: 'transparent',
        borderWidth: '0px',
        borderColor: 'transparent',
        _hover: {
          bg: 'transparent',
          borderWidth: '0px',
          stroke: 'transparent',
        },
      },
      connector: {
        ...Steps.baseStyle(props).connector,
        display: 'none',
      },
    }
  },
}

// See this URL for the defaults
// https://chakra-ui.com/docs/theming/theme

const overrides = {
  styles,
  colors,
  fonts,
  //   borders,
  // Other foundational style overrides go here
  components: {
    Button,
    Text,
    Steps: CustomSteps,
    Checkbox: {
      baseStyle: {
        control: {
          background: 'white',
        },
      },
    },
    // Other components go here
  },
}
export default extendTheme(overrides)
