import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  VStack
} from '@chakra-ui/react'
import { ErrorMessage, FieldArray, useField } from 'formik'
import React, { FC } from 'react'
import { BaseProps } from './base-props'
import DatePickerControl from './DatePickerControl'
import DropdownControl from './DropdownControl'
import InputControl from './InputControl'

export const relationshipType = [
  { key: '', label: 'Select an option' },
  { key: 'Child', label: 'Child' },
  { key: 'Godchild', label: 'Godchild' },
  { key: 'GrandChild', label: 'Grandchild' },
  { key: 'Nephew', label: 'Nephew' },
  { key: 'Niece', label: 'Niece' },
  { key: 'Parent', label: 'Parent' },
  { key: 'Other', label: 'Other' }
]

export type DependentsControlProps = {
  showRelationshipType?: boolean
} & BaseProps

type Dependent = {
  firstName: string
  lastName?: string
  dateOfBirth: Date
  relationshipType?: string
  gender?: string
  xplanItemId?: string
}

const Empty = { firstName: '', lastName: '', dateOfBirth: '', gender: '' }

export const DependentsControl: FC<DependentsControlProps> = (
  props: DependentsControlProps
) => {
  const { name, label, children, ...rest } = props
  const [field, meta] = useField(name)

  const array = field.value as Dependent[]
  return (
    <FormControl id={name} name={name} {...rest}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <DependentsInner
            name={name}
            arrayHelpers={arrayHelpers}
            array={array}
            showRelationshipType={props.showRelationshipType}
          />
        )}
      />
      <ErrorMessage name={props.name}>
        {(errors) =>
          typeof errors === 'string' ? (
            <FormErrorMessage>{errors}</FormErrorMessage>
          ) : null
        }
      </ErrorMessage>
    </FormControl>
  )
}

export const DependentsEdit = ({
  name,
  index,
  showRelationshipType = true
}: {
  name: string
  showRelationshipType?: boolean
  showAddRemove?: boolean
  index?: number
  submitCount?: number
}) => {
  const [{ value: relationshipTypeValue }] = useField(
    `${name}.${index}.relationshipType`
  )
  return (
    <VStack
      key={`${name}.${index}`}
      bg="#E2E7EE"
      p="4"
      mb="4"
      borderRadius="lg"
    >
      <HStack w="full" alignItems="flex-start">
        <InputControl
          name={`${name}.${index}.firstName`}
          label="First Name"
          isRequired
        />
        <InputControl
          name={`${name}.${index}.lastName`}
          label="Last Name"
          isRequired
        />
      </HStack>
      <HStack
        w="full"
        sx={{
          '* .react-datepicker-wrapper': {
            width: '100%'
          }
        }}
        alignItems="flex-start"
      >
        <DatePickerControl
          name={`${name}.${index}.dateOfBirth`}
          label="Date of Birth"
          placeholder="13/04/1990"
          width="500px"
          isRequired
        />
      </HStack>
      <HStack w="full" alignItems="flex-start">
        <DropdownControl
          name={`${name}.${index}.gender`}
          label="Gender"
          dropdownOptions={[
            { key: '', label: 'Select an option' },
            { key: 'male', label: 'Male' },
            { key: 'female', label: 'Female' },
            { key: 'other', label: 'Other' }
          ]}
          useKey
          isRequired
        />
      </HStack>
      {showRelationshipType && (
        <HStack w="full" alignItems="flex-start">
          <DropdownControl
            name={`${name}.${index}.relationshipType`}
            label="Relationship"
            dropdownOptions={relationshipType}
            useKey
            isRequired
          />
        </HStack>
      )}
      {showRelationshipType && relationshipTypeValue === 'Other' && (
        <HStack w="full" alignItems="flex-start">
          <InputControl
            name={`${name}.${index}.relationshipTypeOther`}
            label="Please specify"
            isRequired
          />
        </HStack>
      )}
    </VStack>
  )
}

const DependentsInner = ({
  name,
  arrayHelpers,
  array
}: {
  name: string
  arrayHelpers: any
  array: Dependent[]
  showRelationshipType?: boolean
}) => {
  return (
    <div>
      {array && array.length > 0 ? (
        <>
          {array.map((_, index) => (
            <DependentsEdit
              name={name}
              index={index}
              key={`${name}-${index}`}
            />
          ))}

          <Button
            size="sm"
            variant="ghost"
            onClick={() => arrayHelpers.remove(array.length - 1)}
          >
            Remove one
          </Button>
          <Button
            size="sm"
            variant="ghost"
            onClick={() => arrayHelpers.insert(array.length, Empty)}
          >
            Add dependant
          </Button>
        </>
      ) : (
        <Button
          size="sm"
          variant="ghost"
          onClick={() => arrayHelpers.insert(0, Empty)}
        >
          Add dependant
        </Button>
      )}
    </div>
  )
}
