import { mode, transparentize } from '@chakra-ui/theme-tools'

// Modified from chakra defaults
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/button.ts

function variantGhost(props: any) {
  const { colorScheme: c, theme } = props

  if (c === 'gray') {
    return {
      color: mode(`gray.250`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode(`gray.50`, `whiteAlpha.200`)(props),
      },
      _active: { bg: mode(`gray.100`, `whiteAlpha.300`)(props) },
    }
  }

  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme)
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme)

  return {
    color: mode(`${c}.600`, `${c}.200`)(props),
    bg: 'transparent',
    _hover: {
      bg: mode(`${c}.50`, darkHoverBg)(props),
    },
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props),
    },
  }
}

function variantOutline(props: any) {
  const { colorScheme: c } = props
  const borderColor = mode(`gray.200`, `whiteAlpha.300`)(props)
  return {
    border: '2px solid',
    borderColor: c === 'gray' ? borderColor : 'currentColor',
    ...variantGhost(props),
    bg: 'white',
  }
}

function variantSolid(props: any) {
  const bg = 'blue.800'
  const color = 'white'
  const hoverBg = `blue.600`
  const activeBg = `blue.700`

  const background = mode(bg, `blue.200`)(props)

  return {
    bg: background,
    color: mode(color, `gray.800`)(props),
    _hover: {
      bg: mode(hoverBg, `blue.300`)(props),
      _disabled: {
        bg: background,
      },
      textDecoration: 'none',
    },
    _active: { bg: mode(activeBg, `blue.400`)(props) },
  }
}

function variantLight() {
  return {
    bg: '#F1F8FF',
    color: 'blue.800',
    _hover: {
      bg: '#E2F0FF',
      _disabled: {
        bg: '#F1F8FF',
      },
    },
  }
}

export default {
  baseStyle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '6px',
  },
  sizes: {
    md: {
      fontSize: 'sm',
    },
    lg: {
      fontSize: 'sm',
      px: 8,
    },
  },
  variants: {
    solid: variantSolid,
    outline: variantOutline,
    ghost: variantGhost,
    light: variantLight,
  },
  defaultProps: {
    size: 'lg',
    colorScheme: 'blue',
  },
}
