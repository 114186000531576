import { CheckIcon } from '@chakra-ui/icons'
import { Box, Button } from '@chakra-ui/react'
import React from 'react'

interface CheckButtonProps {
  isCheckedColor: boolean
  onClick?: (itemChecked: any) => void
}

const CheckButton = ({ isCheckedColor, onClick }: CheckButtonProps) => {
  return (
    <Button
      size='sm'
      variant='ghost'
      padding='0'
      textTransform='none'
      sx={{
        bg: 'none !important',
        boxShadow: 'none !important',
        alt: 'complete'
      }}
      onClick={onClick}
    >
      <Box
        bg={isCheckedColor ? '#B6D991' : '#D7D7D7'}
        padding={1}
        borderRadius='50%'
        display='flex'
        h='24px'
      >
        <CheckIcon w={'16px'} h={'16px'} color='white' />
      </Box>
    </Button>
  )
}

export default CheckButton
