import { AppProps } from 'next/app'

import { ChakraProvider } from '@packages/ui-components'
import { Global } from '@emotion/react'

import theme from '../theme'
import fonts from '../styles/font-face'
import 'react-datepicker/dist/react-datepicker.css'
import '../styles/ios-fix.css'

import { init } from '@/sentry'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

init()

const queryClient = new QueryClient()

function DiscoveryContainer({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Global styles={fonts} />
        <Component {...pageProps}></Component>
      </ChakraProvider>

      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default DiscoveryContainer
