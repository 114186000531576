import { Box, Icon } from '@chakra-ui/react'
import React from 'react'

const CircleWithText = ({ innerText }: { innerText: string  }) => {
  return (
    <Box
      height='1.25rem'
      width='1.25rem'
      borderRadius='50%'
      backgroundColor='blue.800'
      color='white'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      ml='4'
      fontWeight='bold'
      fontSize='xs'
      display='inline-block'
      padding='0.5px'
      paddingRight='1px'
    >
      {innerText}
    </Box>
  )
}

export default CircleWithText
