import { MinusIcon } from '@chakra-ui/icons'
import { Box, Button } from '@chakra-ui/react'
import { useField } from 'formik'
import React, { useCallback } from 'react'
interface RemoveButtonProps {
  handleClick: (setValue: any, value: any) => void  
  disableRemove?: boolean
  /** supplying fieldName assumes you have a list that stores items removed from the field array that's been passed to formik - see GoalsProgress.tsx for an example of use*/
  removedItemsFieldName?: string
}

const RemoveButton = ({
  disableRemove,
  removedItemsFieldName,
  handleClick
}: RemoveButtonProps) => {
  const [, { value }, { setValue }] = useField(
    removedItemsFieldName ?? 'noFieldName'
  )

  return (
    <Button
      size='sm'
      variant='ghost'
      padding='0'
      textTransform='none'
      sx={{
        bg: 'none !important',
        boxShadow: 'none !important',
        alt: 'remove'
      }}
      onClick={() => handleClick(setValue, value)}
      disabled={disableRemove}
    >
      <Box
        bg='blue.800'
        transition='background .3s ease'
        padding={1}
        borderRadius='50%'
        display='flex'
        h='24px'
        _hover={{ bg: 'blue.600' }}
        _focus={{ bg: 'blue.600' }}
      >
        <MinusIcon w={'16px'} h={'16px'} color='white' />
      </Box>
    </Button>
  )
}

export default RemoveButton
