import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  RadioProps,
  Stack,
  StackProps
} from '@chakra-ui/react'
import { ErrorMessage, useField, useFormikContext } from 'formik'
import React, { FC, ReactNode } from 'react'
import { BaseProps } from './base-props'

export type RadioSingleProps = BaseProps & {
  radioProps?: RadioProps
  children?: ReactNode
  radioOptions?: { key: unknown; label: string }[]
  radioStackProps?: StackProps
}

// See https://github.com/chakra-ui/chakra-ui/discussions/3262

export const RadioControl: FC<RadioSingleProps> = (props: RadioSingleProps) => {
  const {
    name,
    label,
    children,
    radioOptions,
    radioProps,
    radioStackProps,
    ...rest
  } = props
  const [field, meta] = useField(name)
  const { onChange, value, ...restField } = field
  const formik = useFormikContext()

  const createOnChange = (value: unknown) => () => {
    formik.setFieldValue(name, value)
  }

  return (
    <FormControl
      id={name}
      name={name}
      isInvalid={!!(meta.error && meta.touched)}
      isRequired={props.isRequired}
      {...rest}
    >
      <RadioGroup value={String(value)} {...restField}>
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

        <Stack direction="column" {...radioStackProps}>
          {radioOptions &&
            radioOptions.map((opt) => (
              <Radio
                key={String(opt.key)}
                value={String(opt.key)}
                bg="white"
                borderColor="gray.500"
                onChange={createOnChange(opt.key)}
                {...radioProps}
              >
                {opt.label}
              </Radio>
            ))}
          {children}
        </Stack>
        <ErrorMessage component={FormErrorMessage} name={name} />
      </RadioGroup>
    </FormControl>
  )
}
