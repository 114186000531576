import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
  Spinner
} from '@packages/ui-components'
import { ErrorMessage, useField } from 'formik'
import { BaseProps } from './base-props'
// Only used for RecordRTC type, dynamic import is used in fetchDataAndSetupSocket
import RecordRTC from 'recordrtc'
import { LuMic, LuMicOff } from 'react-icons/lu'

export type InputControlProps = BaseProps & {
  inputProps?: TextareaProps
}

const STTAreaControl = (props: InputControlProps) => {
  const [field] = useField(props.name)
  const [recordingStatus, setRecordingStatus] = useState(false)
  const [connectedStatus, setConnectedStatus] = useState(false)

  const [{ value }, meta, { setValue }] = useField(props.name)

  useEffect(() => {
    let socket: WebSocket | null
    let recorder: RecordRTC | null

    const fetchDataAndSetupSocket = async () => {
      // Dynamically import RecordRTC to avoid SSR issues
      const { default: RecordRTC } = await import('recordrtc')
      try {
        if (recordingStatus === true) {
          const response = await fetch(
            `${process.env.NEXT_PUBLIC_API_BASE_URL}/getAssemblyToken`,
            {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )

          if (!response.ok) {
            throw new Error(
              `Failed to fetch Assembly token: ${response.statusText}`
            )
          }

          const result = await response.json()
          const assemblyToken = result.assemblyToken.token
          // Start new socket with assemblyToken
          socket = new WebSocket(
            `wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${assemblyToken}`
          )

          socket.onopen = () => {
            setConnectedStatus(true)
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then((stream) => {
                recorder = new RecordRTC(stream, {
                  type: 'audio',
                  mimeType: 'audio/webm;codecs=pcm',
                  recorderType: RecordRTC.StereoAudioRecorder,
                  timeSlice: 250,
                  desiredSampRate: 16000,
                  numberOfAudioChannels: 1,
                  bufferSize: 4096,
                  audioBitsPerSecond: 128000,
                  ondataavailable: (blob) => {
                    const reader = new FileReader()
                    reader.onload = () => {
                      const base64data: string | ArrayBuffer | null =
                        reader.result
                      if (
                        socket &&
                        base64data !== null &&
                        typeof base64data === 'string'
                      ) {
                        socket.send(
                          JSON.stringify({
                            audio_data: base64data.split('base64,')[1]
                          })
                        )
                      }
                    }
                    reader.readAsDataURL(blob)
                  }
                })

                recorder.startRecording()
              })
              .catch((err) => console.error(err))
          }
          const texts: { [key: string]: string } = {}
          socket.onmessage = (message) => {
            // onmessage get the the new STT text and append it to the form value
            let combinedText: string = value
            const res = JSON.parse(message.data)
            texts[res.audio_start] = res.text
            const keys = Object.keys(texts)

            keys.sort((a: string, b: string) => parseInt(a) - parseInt(b))
            for (const key of keys) {
              if (texts[key]) {
                combinedText += ` ${texts[key]}`
              }
            }
            // Set the form value to combinedText
            setValue(combinedText)
          }

          socket.onclose = () => {}
          socket.onerror = (e) => {
            console.log(e)
          }
        } else if (recordingStatus === false && socket) {
          // Close socket if recordingStatus is false and socket is open
          socket.close()
          socket.send(JSON.stringify({ terminate_session: true }))
        }
      } catch (error) {
        console.error('Error during setup:', error)
      }
    }
    fetchDataAndSetupSocket()

    return () => {
      if (socket && socket.readyState === socket.OPEN) {
        // Close the socket if it's open
        socket.send(JSON.stringify({ terminate_session: true }))
        socket.close()
        socket = null
        setConnectedStatus(false)
      }
      if (recorder) {
        // Close recorder if it's open
        recorder.pauseRecording()
        recorder = null
      }
    }
  }, [recordingStatus])

  const handleRecordingStatus = () => {
    // set recording status on click
    setRecordingStatus(!recordingStatus)
  }
  return (
    <>
      <FormControl
        id={props.name}
        isInvalid={!!(meta.error && meta.touched)}
        isRequired={props.isRequired}
      >
        <FormLabel htmlFor={props.name}>{props.label}</FormLabel>
        <Textarea
          {...field}
          {...props.inputProps}
          bg="white"
          placeholder={props.placeholder ? props.placeholder : ''}
        />
        <ErrorMessage component={FormErrorMessage} name={props.name} />
      </FormControl>
      <Box py={4}>
        <Button
          variant="outline"
          background={recordingStatus ? 'red.500' : 'transparent'}
          textColor={recordingStatus ? 'white' : 'blue.800'}
          _hover={{
            textColor: recordingStatus ? 'white' : 'blue.800',
            background: recordingStatus ? 'red.600' : 'blue.50',
            borderColor: recordingStatus ? 'red.300' : 'blue.800'
          }}
          borderWidth={1}
          disabled={recordingStatus && !connectedStatus}
          onClick={handleRecordingStatus}
          leftIcon={
            recordingStatus ? <LuMicOff size={20} /> : <LuMic size={20} />
          }
          px={3}
          mr={4}
          isLoading={recordingStatus && !connectedStatus}
          spinner={<Spinner color="white" size="md" />}
          loadingText="Loading"
          textTransform="none"
          borderRadius="lg"
          size="md"
          fontSize="sm"
        >
          {recordingStatus && connectedStatus
            ? 'Stop Transcribing'
            : 'Transcribe notes'}
        </Button>
      </Box>
    </>
  )
}
export default STTAreaControl
