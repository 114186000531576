export default {
  global: {
    // styles for the `body`
    body: {
      fontSize: '16px',
      bg: 'white',
      color: 'gray.900',
    },
    // styles for the `a`
    a: {
      color: 'gray.900',
      fontSize: '16px',
      _hover: {
        textDecoration: 'underline',
      },
    },
  },
}
