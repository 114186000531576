import React from 'react'

function _interpose(arr: any[], sepElement: any) {
  return arr.reduce((acc, child, index) => {
    acc.push(child)
    if (index < arr.length - 1) {
      acc.push(
        React.cloneElement(sepElement, { key: `interpose-${index}-separator` })
      )
    }
    return acc
  }, [])
}

export default function Interpose({
  children,
  separator,
  prefix = null,
  suffix = null
}: {
  children: React.ReactNode
  separator: React.ReactNode
  prefix?: any
  suffix?: any
}) {
  if (React.Children.count(children) === 0) {
    return null
  }

  const result: React.ReactNode[] = []

  if (prefix) {
    result.push(React.cloneElement(prefix, { key: `interpose-prefix` }))
  }

  result.push(..._interpose(React.Children.toArray(children), separator))

  if (suffix) {
    result.push(React.cloneElement(suffix, { key: `interpose-suffix` }))
  }

  return <>{result}</>
}
