import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps
} from '@packages/ui-components'
import { ErrorMessage, useField } from 'formik'
import { BaseProps } from './base-props'

export type InputControlProps = BaseProps & {
  inputProps?: TextareaProps
}

const TextareaControl = (props: InputControlProps) => {
  const [field, meta] = useField(props.name)

  return (
    <FormControl
      id={props.name}
      isInvalid={!!(meta.error && meta.touched)}
      isRequired={props.isRequired}
    >
      <FormLabel htmlFor={props.name}>{props.label}</FormLabel>
      <Textarea {...field} {...props.inputProps} bg="white" />
      <ErrorMessage component={FormErrorMessage} name={props.name} />
    </FormControl>
  )
}
export default TextareaControl
